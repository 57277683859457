import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import {
    FC,
    forwardRef,
    InputHTMLAttributes,
    ReactNode,
    useEffect,
} from 'react';
import ErrorMessage from './FieldErrorMessage';
import styles from './Field.module.scss';
import { Form } from 'react-bootstrap';

export type FieldProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange'
> & {
    id?: string;
    name: string;
    label?: string;
    onChange?: <Value = unknown>(value: Value) => void;
    readOnly?: boolean;
    className?: string;
    classNameLabel?: string;
    type?: string;
    hint?: string;
    appendGroup?: ReactNode;
    currency?: boolean;
};

type FieldGroupProps = {
    appendGroup?: ReactNode;
};

const FieldGroup: FC<FieldGroupProps> = ({ appendGroup, children }) => {
    if (!!appendGroup) {
        return (
            <>
                {children}
                <div className={styles.input_group_append}>{appendGroup}</div>
            </>
        );
    }
    return <>{children}</>;
};

const Field = forwardRef<HTMLInputElement, FieldProps>(
    (
        {
            id,
            name,
            label,
            className,
            readOnly,
            classNameLabel,
            type = 'text',
            disabled = false,
            hint,
            appendGroup,
            currency = false,
            ...props
        },
        ref,
    ) => {
        const [
            { onChange, value, ...fieldProps },
            { touched, error },
        ] = useField(name);
        const { isSubmitting } = useFormikContext();
        useEffect(() => {
            if (props.onChange) {
                props.onChange(value);
            }
        }, [props, value]);
        return (
            <FieldGroup appendGroup={appendGroup}>
                <input
                    {...fieldProps}
                    ref={ref}
                    maxLength={props.maxLength}
                    id={id}
                    key={fieldProps.name}
                    className={classNames(
                        className,
                        {
                            [styles.invalid_field]: touched && error,
                            [styles.disabled]: disabled || isSubmitting,
                        },
                        'form-control',
                    )}
                    disabled={disabled || isSubmitting}
                    placeholder={label}
                    readOnly={readOnly}
                    value={value}
                    type={type}
                    onKeyPress={(event) => {
                        if (props.onKeyPress) {
                            props.onKeyPress(event);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (props.onKeyDown) {
                            props.onKeyDown(e);
                        }
                    }}
                    onChange={onChange}
                    onFocus={props.onFocus}
                    onBlur={(event) => {
                        fieldProps.onBlur(event);
                        if (props.onBlur) {
                            props.onBlur(event);
                        }
                        // setValue(
                        //     parseFloat(event.target.value || '0')
                        //         .toFixed(2)
                        //         .replace(/\,/g, '.'),
                        // );
                    }}
                    onPaste={props.onPaste}
                    step={type === 'number' ? '0.01' : undefined}
                    min={type === 'number' ? '0' : undefined}
                    lang={type === 'number' ? 'en' : undefined}
                />
                <label htmlFor={id} className={classNames(classNameLabel)}>
                    {label}
                </label>
                {hint && (
                    <Form.Text id={`${fieldProps.name}HelpBlock`} muted>
                        {hint}
                    </Form.Text>
                )}
                <ErrorMessage name={name} />
            </FieldGroup>
        );
    },
);

export default Field;
