/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField, useFormikContext } from 'formik';
import { useRef, useState, useEffect, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { PageComponent } from '@root/pages/Type';
import { Form } from 'react-bootstrap';
import ErrorMessage from './FieldErrorMessage';
import styles from './Field.module.scss';
import classNames from 'classnames';

type PasswordFieldProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange'
> & {
    id?: string;
    name: string;
    label?: string;
    idicator?: boolean;
    hint?: string;
    onChange?: (value: string | undefined) => void;
    classNameLabel?: string;
};

const passwordStrength = (pw: string) => {
    return (
        (/.{6,}/.test(pw) ? 1 : 0) /* at least 6 characters */ +
        (/.{12,}/.test(pw) ? 1 : 0) /* bonus if longer */ +
        (/.{10,}/.test(pw) ? 1 : 0) /* bonus if longer */ +
        (/[a-z]/.test(pw) ? 1 : 0) /* a lower letter */ +
        (/[A-Z]/.test(pw) ? 1 : 0) /* a upper letter */ +
        (/\d/.test(pw) ? 1 : 0) /* a digit */ +
        (/[^A-Za-z0-9]/.test(pw) ? 1 : 0) /* a special character */
    );
};

const indicatorShowStyle = {
    opacity: 1,
    transition: 'all 0.2s',
};
const indicatorHideStyle = {
    opacity: 0,
    transition: 'all 0.2s',
};

const classList = ['weak', 'medium', 'strong'];

const Password: PageComponent<PasswordFieldProps> = ({
    id,
    name,
    label,
    hint,
    idicator = true,
    className,
    classNameLabel,
    ...props
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [passwordClass, setPasswordClass] = useState(classList[0]);
    const [passwordText, setPasswordText] = useState(classList[0]);
    const [passwordLevel, setPasswordLevel] = useState(0);
    const [showPasswordIndicator, setShowPasswordIndicator] = useState(false);
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const textList = [
        t('password_indicator.weak', {
            defaultValue: 'Silpnas',
            ns: 'validation',
        }),
        t('password_indicator.medium', {
            defaultValue: 'Vidutinis',
            ns: 'validation',
        }),
        t('password_indicator.strong', {
            defaultValue: 'Stiprus',
            ns: 'validation',
        }),
    ];

    const [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        { onChange, onBlur, value, ...fieldProps },
        { touched, error },
    ] = useField(name);
    const { isSubmitting } = useFormikContext();

    useEffect(() => {
        let passStr = passwordLevel;
        if (passStr > 0) {
            passStr = Math.floor(passStr / 2);
        }
        if (passStr > 1) {
            setPasswordClass(classList[passStr - 1]);
            setPasswordText(textList[passStr - 1]);
        } else {
            setPasswordClass(classList[0]);
            setPasswordText(textList[0]);
        }
    }, [passwordLevel, textList]);

    useEffect(() => {
        setPasswordLevel(passwordStrength(value));
        if (props.onChange) {
            props.onChange(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        return () => {
            inputRef.current?.blur();
        };
    }, []);

    return (
        <>
            <input
                {...fieldProps}
                ref={inputRef}
                id={id}
                key={name}
                type="password"
                className={classNames('form-control', className, {
                    [styles.invalid_field]: touched && error,
                })}
                disabled={isSubmitting}
                placeholder={label}
                value={value}
                onFocus={() => {
                    setShowPasswordIndicator(true);
                }}
                onChange={onChange}
                onBlur={(event) => {
                    setShowPasswordIndicator(false);
                    onBlur(event);
                }}
            />
            {label && (
                <label htmlFor={id} className={classNames(classNameLabel)}>
                    {label}
                </label>
            )}
            {hint && (
                <Form.Text id="passwordHelpBlock" muted>
                    {hint}
                </Form.Text>
            )}
            <ErrorMessage name={name} />
            {idicator && (
                <div
                    className="row password-indicator-container"
                    style={
                        showPasswordIndicator
                            ? indicatorShowStyle
                            : indicatorHideStyle
                    }
                >
                    <div
                        className={`col-6 password-indicator ${passwordClass}`}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                    <div className="col-6 password-indicator-text">
                        {passwordText}
                    </div>
                </div>
            )}
        </>
    );
};

export default Password;
