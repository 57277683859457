// TODO: check theese errors?:
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useField, useFormikContext } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { PageComponent } from '@root/pages/Type';
import styles from './Switch.module.scss';
import ErrorMessage from './FieldErrorMessage';

export type SwitchProps = {
    name: string;
    label?: ReactNode | string;
    onChange?: (value: string | number | boolean) => void;
    readOnly?: boolean;
    className?: string;
    checked?: boolean;
    labelAsSuffix?: boolean;
    switchRightFixed?: boolean;
    disabled?: boolean;
};

const Switch: PageComponent<SwitchProps> = ({
    name,
    checked,
    className,
    label,
    readOnly,
    labelAsSuffix = false,
    switchRightFixed = false,
    disabled = false,
    ...props
}) => {
    const [value, setValue] = useState('');

    const [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        { onChange, value: _value, ...fieldProps },
    ] = useField(name);
    const { isSubmitting, setFieldValue } = useFormikContext();
    const [state, setState] = useState(checked || _value || false);
    /*
    useEffect(() => {
        setState(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    */
    useEffect(() => {
        if (checked) {
            setState(true);
        }
    }, [checked]);

    return (
        <div className={classNames(className, styles.toggle)}>
            <input
                key={fieldProps.name}
                className={styles.input}
                readOnly={readOnly}
                disabled={disabled || isSubmitting}
                checked={state}
                value={value}
                onChange={(e) => {
                    onChange(e);
                    setValue(e.target.value);
                }}
                {...fieldProps}
            />
            <label
                htmlFor={fieldProps.name}
                className={classNames(styles.label, {
                    [styles.checked]: state,
                    [styles.labelSsSuffix]: labelAsSuffix,
                    [styles.switchRightFixed]: switchRightFixed,
                })}
            >
                {labelAsSuffix === false && label}
                <span
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        if (props.onChange) props.onChange(!state);
                        setFieldValue(fieldProps.name, !state);
                        setState(!state);
                    }}
                />
                {labelAsSuffix === true && label}
            </label>
            <ErrorMessage name={name} />
        </div>
    );
};

export default Switch;
