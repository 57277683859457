import { PageComponent } from '@root/pages/Type';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import styles from './ErrorMessage.module.scss';
import { Element, scroller } from 'react-scroll';
import classNames from 'classnames';

type ErrorInnerMessageProps = {
    errorMessage: string;
};

type ErrorMessage = {
    errorMessage: string | null;
    className?: string;
};

const ErrorInnerMessage: PageComponent<
    Omit<ErrorMessage, 'errorMessage'> & ErrorInnerMessageProps
> = ({ errorMessage, className }) => {
    useEffect(() => {
        scroller.scrollTo('error-message-container', {
            duration: 500,
            delay: 100,
            smooth: true,
        });
    }, []);
    return (
        <Element name="error-message-container">
            <div className={classNames(styles.container, className)}>
                <strong>
                    <Trans i18nKey="errorMessage.title" ns="uiComponent">
                        Klaida:
                    </Trans>
                </strong>{' '}
                {errorMessage}
            </div>
        </Element>
    );
};

const ErrorMessage: PageComponent<ErrorMessage> = ({
    errorMessage,
    ...props
}) => {
    if (!errorMessage) return null;
    return <ErrorInnerMessage errorMessage={errorMessage} {...props} />;
};

export default ErrorMessage;
