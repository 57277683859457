import { PageComponent } from '@root/pages/Type';
import { useField } from 'formik';
import styles from './FieldErrorMessage.module.scss';

type FieldErrorMessage = {
    name: string;
};

const FieldErrorMessage: PageComponent<FieldErrorMessage> = ({ name }) => {
    const [, { touched, error }] = useField(name);
    if (!error || !touched) return null;
    return <span className={styles.content}>{error}</span>;
};

export default FieldErrorMessage;
