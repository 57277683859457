// TODO: add keyboard events
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Check this rule:
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import { FormikProps, useFormikContext, useField } from 'formik';
import { useOnClickOutside } from '@utils/hooks';
import { PageComponent } from '@root/pages/Type';
import classNames from 'classnames';
import ErrorMessage from './FieldErrorMessage';
import styles from './Select.module.scss';
import classnames from 'classnames';
import CaretUp from '@static/img/icons/caret-up.svg';
import CaretDown from '@static/img/icons/caret-down.svg';

export type SelectOption = {
    label: string;
    value: string | number;
};
export type SelectTranslatedOption = {
    translate: string;
    translateKey: string;
    value: string | number;
};

export type SelectProps = {
    options: Array<SelectOption | SelectTranslatedOption>;
    className?: string;
    name: string;
    label?: string;
    onChange?: (value: string | number) => void;
    disabled?: boolean;
    ns?: string;
    autoComplete?: string;
};

const Select: PageComponent<SelectProps> = ({
    name,
    disabled = false,
    label,
    onChange,
    autoComplete,
    ...props
}) => {
    const { options, className } = props;
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [field, {touched, error}] = useField(name);
    const {
        isSubmitting,
        initialValues,
        setFieldTouched,
        setFieldValue,
    }: FormikProps<string | number> = useFormikContext();

    const [selected, setSelected] = useState<
        SelectOption | SelectTranslatedOption | undefined
    >(
        initialValues && options
            ? options.find((e) => e.value === initialValues[name])
            : undefined,
    );

    useOnClickOutside(ref, () => setIsOpen(false));

    useEffect(() => {
        if (options) {
            setSelected(options.find((e) => e.value === field.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value, options]);

    useEffect(() => {
        if (!isOpen) {
            setFieldTouched(name, true);
        }
    }, [isOpen]);

    const handleClick = <
        E extends React.MouseEvent<HTMLDivElement, MouseEvent>
    >(
        event: E,
    ): void => {
        event.preventDefault();
        if (disabled || isSubmitting) {
            return;
        }
        setIsOpen(!isOpen);
    };
    const handleOptionClick = useCallback(
        <E extends React.MouseEvent<HTMLLIElement, MouseEvent>>(
            event: E,
            option: SelectOption | SelectTranslatedOption,
        ): void => {
            event.stopPropagation();
            setIsOpen(false);
            setSelected(option);
            setFieldTouched(name, true);
            setFieldValue(name, option.value);
            if (onChange) onChange(option.value);
        },
        [name, onChange, setFieldTouched, setFieldValue],
    );

    const renderSelected = useMemo(() => {
        if (!selected) return null;
        if ('translate' in selected) {
            return selected.translate;
        }
        return selected.label;
    }, [selected]);

    const optionsList = useMemo(
        () =>
            options
                ? options.map((option) => (
                      <option
                          key={`select-${name}-o-${option.value}`}
                          value={option.value}
                      >
                          {'translate' in option
                              ? option.translate
                              : option.label}
                      </option>
                  ))
                : [],
        [name, options],
    );

    const optionsPreviewList = useMemo(
        () =>
            options
                ? options.map((option) => {
                      return (
                          <li
                              key={`select-${name}-li-${option.value}`}
                              onClick={(event) =>
                                  handleOptionClick(event, option)
                              }
                          >
                              {'translate' in option
                                  ? option.translate
                                  : option.label}
                          </li>
                      );
                  })
                : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name, options],
    );

    return (
        <div
            className={classNames('form-select', {
                disabled: disabled || isSubmitting,
                'has-value': !!selected,
                [styles.invalid_field]: touched && error
            })}
            ref={ref}
            onClick={handleClick}
            key={`select-${name}`}
        >
            <select
                className={classNames(
                    `form-control selectpicker`,
                    styles.select_hide,
                    {
                        show: isOpen,
                        disabled: disabled || isSubmitting,
                    },
                    className,
                )}
                key={`select-${name}-select`}
                disabled={disabled || isSubmitting}
                onFocus={() => setIsOpen(true)}
                onBlur={() => {
                    setFieldTouched(name, true);
                    setIsOpen(false);
                }}
                onChange={(value) => setFieldValue(name, value)}
                autoComplete={autoComplete}
            >
                {optionsList}
            </select>
            <div className={classnames("text", styles.text)}>{renderSelected}</div>
            {typeof label !== 'undefined' && (
                <label htmlFor={name}>{label}</label>
            )}
            <ul
                className={`select-content ${isOpen ? 'show' : ''}`}
                key={`select-${name}-ul`}
            >
                {optionsPreviewList}
            </ul>
            <ErrorMessage name={name} />
            <div className={styles.carret}>
                {isOpen ? <CaretUp/> : <CaretDown/>}
            </div>
        </div>
    );
};

export default Select;
