// TODO: Add images alt tags
/* eslint-disable jsx-a11y/alt-text */
import classnames from 'classnames';
import React from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './Button.module.scss';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isLoading?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
    const {
        children,
        disabled,
        className,
        isLoading = false,
        ...btnProps
    } = props;
    const [showLoader, setShowLoader] = React.useState(false);
    const ref = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (isLoading) {
            setShowLoader(true);
        }

        // Show loader a bits longer to avoid loading flash
        if (!isLoading && showLoader) {
            timeout = setTimeout(() => {
                setShowLoader(false);
            }, 400);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isLoading, showLoader]);

    /* Capture the dimensions of the button before the loading happens
        so it doesn’t change size.
        These hooks can be put in a seprate file. */
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const updateElementSize = () => {
        setWidth(0);
        setHeight(0);
        if (ref.current && ref.current.getBoundingClientRect().width) {
            setWidth(ref.current.getBoundingClientRect().width);
        }
        if (ref.current && ref.current.getBoundingClientRect().height) {
            setHeight(ref.current.getBoundingClientRect().height);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateElementSize);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', updateElementSize);
        };
    }, []);

    React.useEffect(() => {
        updateElementSize();
    }, [children, ref]);
    const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 });
    const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 });
    return (
        // eslint-disable-next-line react/button-has-type
        <button
            {...btnProps}
            className={classnames(className, styles.button)}
            ref={ref}
            disabled={disabled || showLoader}
            style={
                width && height
                    ? {
                        width: `${width}px`,
                        height: `${height}px`,
                    }
                    : {}
            }
        >
            {showLoader ? (
                <animated.div style={fadeOutProps}>
                    <div className={styles.loader} />
                </animated.div>
            ) : (
                <animated.div style={fadeInProps}>{children}</animated.div>
            )}
        </button>
    );
};

export default Button;
