import { PageComponent } from '@root/pages/Type';
import { Field as FormikField } from 'formik';
import { ReactNode } from 'react';
import ErrorMessage from './FieldErrorMessage';
import styles from './Checkbox.module.scss';
import classnames from 'classnames';
import CheckIcon from '@static/img/icons/check.svg';

type CheckboxProps = {
    name: string;
    children: ReactNode;
};

const Checkbox: PageComponent<CheckboxProps> = ({ name, children }) => {
    return (
        <FormikField type="checkbox" name={name}>
            {({
                field, // { name, value, onChange, onBlur }
                form: { isSubmitting, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta: { touched, error }
            }) => (
                <>
                    <div className="form-check">
                        <input
                            key={field.name}
                            className="form-check-input"
                            type="checkbox"
                            id={field.name}
                            disabled={isSubmitting}
                            checked={field.value}
                            {...field}
                        />
                        <div className={classnames(styles.checkbox, {
                            [styles.checked]: field.value,
                            [styles.invalid_field]: touched && error
                        })} onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(field.name, !field.value)
                        }}>
                            {field.value && <CheckIcon/>}</div>
                        <label
                            className="form-check-label"
                            htmlFor={field.name}
                        >
                            {children}
                        </label>
                    </div>
                    <ErrorMessage name={name} />
                </>
            )}
        </FormikField>
    );
};

export default Checkbox;
