import { useField } from 'formik';
import classNames from 'classnames';
import { PageComponent } from '@root/pages/Type';
import { TextareaHTMLAttributes } from 'react';
import ErrorMessage from './FieldErrorMessage';
import styles from './Textarea.module.scss';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    name: string;
    label?: string;
    onChange?: (value: string) => void;
    readOnly?: boolean;
    className?: string;
    classNameLabel?: string;
};

const Textarea: PageComponent<TextareaProps> = ({
    name,
    className,
    readOnly,
    label,
    classNameLabel,
    ...props
}) => {
    const [{ onChange, ...fieldProps }, {touched, error}] = useField(name);
    return (
        <>
            <textarea
                key={fieldProps.name}
                className={classNames(className, {
                    [styles.invalid_field]: touched && error
                }, 'form-control')}
                placeholder={label}
                readOnly={readOnly}
                onChange={(e) => {
                    onChange(e);
                    if (props.onChange) props.onChange(fieldProps.value);
                }}
                {...fieldProps}
            />
            <label
                htmlFor={fieldProps.name}
                className={classNames(classNameLabel)}
            >
                {label}
            </label>
            <ErrorMessage name={name} />
        </>
    );
};

export default Textarea;
