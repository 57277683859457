/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PageComponent } from '@root/pages/Type';
import ErrorMessage from './FieldErrorMessage';

type CaptchaProps = {
    className?: string;
};

const Captcha: PageComponent<CaptchaProps> = ({ className }) => {
    return (
        <>
            <div className={className}>
                <ErrorMessage name="recaptcha" />
            </div>
        </>
    );
};

Captcha.displayName = 'Captcha';

export default Captcha;
